<script>
import { defineComponent } from '@vue/composition-api'
import { MailTemplateList } from '@/composites/mail-template'

export default defineComponent({
  name: 'MailTemplate',

  components: {
    MailTemplateList,
    BaseLayout: () => import('@/views/shared/BaseLayout.vue'),
  },

  render() {
    return <MailTemplateList />
  },
})
</script>
